<template>
  <Header title="Detail odberu" />
  <router-link to="/" class="back-nav-button"><i class="bi bi-arrow-left"></i></router-link>
  <div id="odber-detail" class="container">
    <div class="odber-details">
      <div class="row dl">
        <div class="col-6 dt">Dátum</div>
        <div class="col-6 dd">{{odberData?.DatumOdberu ? new Date(odberData?.DatumOdberu).toLocaleDateString() : '-'}}</div>
      </div>
      <div class="row dl">
        <div class="col-6 dt">Množstvo</div>
        <div class="col-6 dd">{{odberData?.MnozstvoMl ? formatAmount(odberData?.MnozstvoMl) : '-'}}</div>
      </div>
      <div class="row dl">
        <div class="col-6 dt">Typ odberu</div>
        <div class="col-6 dd">{{odberData?.TypOdberu ? odberData?.TypOdberu : '-'}}</div>
      </div>
      <div class="row dl">
        <div class="col-6 dt">Pracovisko</div>
        <div class="col-6 dd">{{odberData?.Pracovisko ? odberData?.Pracovisko : '-'}}</div>
      </div>
      <div class="row dl">
        <div class="col-6 dt">Začiatok</div>
        <div class="col-6 dd">{{odberDetail?.Zaciatok ? new Date(odberDetail?.Zaciatok).toLocaleTimeString() : '-'}}</div>
      </div>
      <div class="row dl">
        <div class="col-6 dt">Koniec</div>
        <div class="col-6 dd">{{odberDetail?.Koniec ? new Date(odberDetail?.Koniec).toLocaleTimeString() : '-'}}</div>
      </div>
      <div class="row dl">
        <div class="col-6 dt">Trvanie</div>
        <div class="col-6 dd">{{odberDetail?.Trvanie ? odberDetail?.Trvanie + ' min.' : '-'}}</div>
      </div>
      <div class="row dl">
        <div class="col-6 dt">Komplikácia</div>
        <div class="col-6 dd">{{odberDetail?.Komplikacia ? odberDetail?.Komplikacia : '-'}}</div>
      </div>
      <div class="row dl">
        <div class="col-6 dt">Výjazd</div>
        <div class="col-6 dd">{{odberDetail?.Vyjazd ? "Áno" : "Nie"}}</div>
      </div>
      <div class="row dl">
        <div class="col-6 dt">Autoodber</div>
        <div class="col-6 dd">{{odberDetail?.Autoodber ? "Áno" : "Nie"}}</div>
      </div>
    </div>

    <div class="params">
      <div class="row">
        <div class="col-12">
          <span class="params-headline item-list-heading">Parametre krvného obrazu:</span>
          <ul class="params-list item-list" v-if="odberIvko?.length > 0">
            <li v-for="(item, index) in odberIvko" v-bind:key="index" class="params-item item-list-item" @click="this.openModal(item.IdParamKO)">
              <span class="line-text">
                <span class="line-primary">{{ getIvkoEnum(item.IdParamKO)?.Nazov }} <span class="unit">[{{getIvkoEnum(item.IdParamKO)?.MernaJednotka}}]</span></span>
                <span class="line-secondary">
                  <span class="min">{{getIvkoMin(item.IdParamKO)}} &#60; </span>
                  <span class="value" :class="item.MimoLimit ? 'warning' : ''">{{ item.Hodnota }} [{{getIvkoEnum(item.IdParamKO)?.MernaJednotka}}]</span>
                  <span class="max"> &#60; {{getIvkoMax(item.IdParamKO)}}</span>
                </span>
              </span>
              <a class="info-button"><i class="bi bi-info-circle-fill"/></a>
            </li>
          </ul>
          <span v-if="odberIvko === null || odberIvko?.length === 0" class="no-data">Nenašli sa žiadne parametre</span>
        </div>
      </div>
    </div>

    <div class="nts-modal-overlay" v-if="modalOpened === true" @click="closeModal" />
    <div class="nts-modal container" v-if="modalOpened === true">
      <div class="row">
        <div class="col-12">
          <h4>{{ getIvkoEnum(ivkoId)?.Nazov }}</h4>
          <h5 class="mb-4">{{ getIvkoEnum(ivkoId)?.Kod }} [{{getIvkoEnum(ivkoId)?.MernaJednotka}}]</h5>
          <span class="close-modal" @click="closeModal"><i class="bi bi-x-lg"></i></span>
        </div>
        <div class="nts-modal-body">
          <IvkoGraph :graph-data="odberIvkoDetail" :ivko="getIvkoEnum(ivkoId)" :graph-min="getIvkoMin(ivkoId)" :graph-max="getIvkoMax(ivkoId)" />
        </div>
        <pre class="d-none">{{odberIvkoDetail}}</pre>
      </div>
    </div>

    <pre class="d-none">{{$route.params.id}}</pre>
    <pre class="d-none">{{odberIvko}}</pre>
    <pre class="d-none">{{ivkoEnums}}</pre>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import Header from '@/components/Header'
import IvkoGraph from '@/components/IvkoGraph'

export default {
  name: 'OdberDetail',
  components: {
    IvkoGraph,
    Header
  },
  data () {
    return {
      odberId: null,
      modalOpened: false,
      ivkoId: null
    }
  },
  beforeMount () {
    this.fetchData()
  },
  watch: {
    '$store.state.isLoggedIn': function () {
      this.fetchData()
    },
    '$route.params': function (after) {
      if (Object.hasOwn(after, 'id')) {
        this.fetchData()
      }
    }
  },
  computed: {
    ...mapState([
      'isLoggedIn',
      'ivkoEnums',
      'userSex',
      'userHistory',
      'odberIvko',
      'odberDetail',
      'odberIvkoDetail'
    ]),
    odberData () {
      return this.userHistory?.find((item) => String(item.Id) === String(this.odberId))
    }
  },
  methods: {
    checkOdberId (id) {
      return id && !isNaN(id)
    },
    fetchData () {
      const isIdOk = this.checkOdberId(this.$route.params?.id)

      if (this.isLoggedIn && isIdOk) {
        this.userHistory === null && this.$store.dispatch('fetchUserHistory')
        this.userSex === null && this.$store.dispatch('fetchUserHome')
        this.ivkoEnums === null && this.$store.dispatch('fetchIvkoEnums')
        if (this.odberIvko === null || this.$route.params?.id !== this.odberId) {
          this.odberId = this.$route.params.id
          this.$store.dispatch('fetchOdberDetail', this.odberId)
          this.$store.dispatch('fetchOdberIvko', this.odberId)
        }
      } else {
        !isIdOk && this.$router.push('/')
      }
    },
    formatAmount (amount) {
      return (isNaN(amount) ? '0' : Number(amount)) + ' ml'
    },
    getIvkoEnum (ivkoId) {
      return this.ivkoEnums?.find((item) => item.Id === ivkoId)
    },
    getIvkoMin (ivkoId) {
      const ivko = this.getIvkoEnum(ivkoId)
      return ivko?.['DolnaHranica' + (this.userSex === 'M' ? 'Muzi' : 'Zeny')]
    },
    getIvkoMax (ivkoId) {
      const ivko = this.getIvkoEnum(ivkoId)
      return ivko?.['HornaHranica' + (this.userSex === 'M' ? 'Muzi' : 'Zeny')]
    },
    openModal (id) {
      this.modalOpened = true
      this.ivkoId = id
      this.$store.dispatch('fetchOdberIvkoDetail', { odberId: this.odberId, ivkoId: this.ivkoId })
    },
    closeModal () {
      this.modalOpened = false
      this.ivkoId = null
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../functions.scss";

#odber-detail {
  position: relative;
  .params {
    .params-list {
      .unit {
        filter: brightness(80%);
      }
      .line-secondary {
        .value {
          color: var(--bs-success);
          filter: brightness(1.5);
          &.warning {
            color: var(--bs-danger)
          }
        }
      }
    }
    .no-data {
      display: block;
      margin: 15px 0;
      font-size: rem(18);
    }
  }

  .nts-modal {
    h4 {
      font-weight: bold;
      margin-top: 30px;
    }
    h5 {
      filter: brightness(80%);
    }
    .nts-modal-body {
      position: relative;
    }
  }
}
</style>
