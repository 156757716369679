<template>
  <div class="ivko-graph">
    <div class="ivko-graph-inner">
      <div class="ivko-graph-bar"
              v-for="(item, index) in graphData" v-bind:key="index"
              data-bs-toggle="tooltip" data-bs-placement="top" :data-bs-title="getTooltip(item)" data-bs-trigger="manual" data-bs-offset="0,5"
              :style="{width: barWidth, height: getHeight(item) + '%'}"
              :class="(item.MimoLimit ? 'danger' : '') + (index === this.getSelectedBarIndex() ? ' selected' : '')"
              @click="selectBar(index)"
      ></div>
    </div>
    <div class="ivko-graph-limit ivko-graph-min" :style="{bottom: getHeight(graphMin) + '%'}"><span>{{graphMin}}</span></div>
    <div class="ivko-graph-limit ivko-graph-max" :style="{bottom: getHeight(graphMax) + '%'}"><span>{{graphMax}}</span></div>
  </div>
</template>

<script>
export default {
  name: 'IvkoGraph',
  props: [
    'graphData',
    'graphMin',
    'graphMax',
    'ivko'
  ],
  data () {
    return {
      tooltipList: [],
      selectedBarIndex: null,
      initial: true
    }
  },
  updated () {
    if (this.initial) {
      const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
      // eslint-disable-next-line no-undef
      this.tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
      this.tooltipList[this.getSelectedBarIndex()].show()
      this.initial = false
    }
  },
  beforeUnmount () {
    this.tooltipList.forEach((item) => {
      item.dispose()
    })
  },
  computed: {
    maxValue () {
      const values = this.graphData?.map((item) => Number(item.Hodnota)) || []
      return Math.max(...values, this.graphMax)
    },
    minValue () {
      const values = this.graphData?.map((item) => Number(item.Hodnota)) || []
      return Math.min(...values, this.graphMin)
    },
    barWidth () {
      return 'calc(' + (80 / this.graphData?.length) + '% - 15px)'
    },
    preselectedBarIndex () {
      return this.graphData.map(item => item.IsSelected).indexOf(true)
    }
  },
  methods: {
    getTooltip (item) {
      return item.Hodnota + ' (' + new Date(item.Datum).toLocaleDateString() + ')'
    },
    getMappedValue (value, inMin, inMax, outMin, outMax) {
      return (value - inMin) * (outMax - outMin) / (inMax - inMin) + outMin
    },
    getHeight (item) {
      const value = isNaN(item) ? item.Hodnota : Number(item)
      return this.getMappedValue(value, this.minValue, this.maxValue, 20, 80)
    },
    getSelectedBarIndex () {
      return this.selectedBarIndex !== null ? this.selectedBarIndex : this.preselectedBarIndex
    },
    selectBar (index) {
      if (this.getSelectedBarIndex() !== index) {
        this.tooltipList[this.getSelectedBarIndex()].hide()
        this.selectedBarIndex = index
        this.tooltipList[this.getSelectedBarIndex()].show()
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.ivko-graph {
  position: relative;
  width: 100%;
  height: 300px;
  overflow-x: auto;
  overflow-y: hidden;

  .ivko-graph-inner {
    display: flex;
    height: 100%;
    align-items: flex-end;
    justify-content: center;

    .ivko-graph-bar {
      display: inline-block;
      margin-left: 15px;
      background-color: var(--bs-green);
      &.selected {
        filter: brightness(0.5);
      }
      &.danger {
        background-color: var(--bs-red);
      }
      &:first-child {
        margin-left: 0;
      }
    }
  }

  .ivko-graph-limit {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    border-bottom: 1px dotted var(--border-color);
    text-align: left;
    padding-left: 5px;
  }
}
</style>
